<template>
    <div>
    <PopupDialog :dialogOpen="dialog"/>    
    <v-btn text color="primary" class="mx-0 mt-3 mb-5 plain" @click="$router.push({name: 'WebAppDashboard'})">
      <v-icon class="mr-2">arrow_back</v-icon>
      <span class="text-none">{{ $t("event.back") }}</span>
    </v-btn>
    <v-alert v-if="error" type="error" outlined>
      {{ error }}
    </v-alert>
    <v-card :class="[$vuetify.breakpoint.smAndDown ? 'pa-3' : 'pa-10 mx-10']" flat style="border-radius: 20px">
        <div :class="['primary--text', $vuetify.breakpoint.smAndDown ? 'headline' : 'display-1', $vuetify.breakpoint.smAndDown ? 'ma-5' : 'mb-5']">{{ $t("event.create") }}</div>
        <EventForm @submit="createEvent" mode="create"/>
    </v-card>
    </div>
</template>

<script>
import axios from 'axios'
import utilitiesMixin from '@/mixins/utilitiesMixin.js'
import PopupDialog from '@/components/partials/PopupDialog'
import EventForm from '@/components/events/EventForm'
    export default {
        name: "WebAppEventCreate",
        components: {PopupDialog, EventForm},
        mixins: [utilitiesMixin],
        data() {
            return {
                error: '',
                dialog: false,
            }
        },
        methods: {
            createEvent(eventData) {
                if (eventData.place == null) {
                    eventData.coords = this.$store.state.regions[eventData.region].location
                }
                let dataToPost = {
                    title: eventData.title,
                    desc: eventData.desc,
                    demand: parseInt(eventData.demand),
                    isProtected: eventData.isProtected | 0,
                    tags: eventData.tags,
                    place: eventData.place,
                    region: this.$store.state.regions[eventData.region].code
                }
                if (eventData.coords != null) {
                    dataToPost = {...dataToPost, latitude: eventData.coords.lat, longitude: eventData.coords.lng}
                }

                if (eventData.timeType == 0) {
                    if (eventData.fullDay) {
                        dataToPost = {...dataToPost, type: 1, date: eventData.date, endDate: eventData.date}
                    }
                    else {
                        dataToPost = {...dataToPost, type: 0, date: eventData.date, endDate: eventData.date, time: eventData.time, endTime: eventData.endTime }
                    }                        
                }
                else if (eventData.timeType == 1) {
                    if (eventData.fullDay) {
                        dataToPost = {...dataToPost, type: 1, date: eventData.date, endDate: eventData.endDate}
                    }
                    else {
                        dataToPost = {...dataToPost, type: 0, date: eventData.date, endDate: eventData.endDate, time: eventData.time, endTime: eventData.endTime }
                    }
                }
                else if (eventData.timeType == 2) {
                    if (eventData.fullDay) {
                        dataToPost = {...dataToPost, type: 2, weekdays: this.parseWeekdays(eventData.weekdays)}
                    }
                    else {
                        dataToPost = {...dataToPost, type: 3, weekdays: this.parseWeekdays(eventData.weekdays), time: eventData.time, endTime: eventData.endTime }
                    }
                }
                else if (eventData.timeType == 3) {
                    dataToPost = {...dataToPost, type: 4}
                }

                this.loading = true
                axios.post('/auth/create_offer', dataToPost)
                .then(() => {
                    this.error = ''
                    this.loading = false
                    this.$router.push({name: 'WebAppDashboard', params: {created: true}})
                })
                .catch(error => {
                    if (error.response && (error.response.data == "Access Denied" || error.response.data == "Invalid Token")) {
                        this.$router.push({ name: 'logout' })
                    } else if (error.response && error.response.data && error.response.data.message) {
                        const msg =  error.response.data.message
                        this.loading = false
                        if (msg.includes('event ends before it starts')){
                            this.error = this.$t("event.errors.endBeforeStart")
                            window.scrollTo(0,0)
                        } else if (msg.includes('INVALID DATA')) {
                            this.error = this.$t("formError")
                            window.scrollTo(0,0)
                        } else if (msg === "Unable to geocode the place - did you enter a correct one?") {
                            this.error = this.$t("event.errors.invalidAddress")
                            window.scrollTo(0,0)
                        } else {
                            this.dialog = true
                        }
                    } else {
                        this.dialog = true
                    }
                
                })
            },
        }
    }
</script>

<style lang="scss" scoped>

</style>